import React from 'react'
import { Helmet } from 'react-helmet'
import { PageLayout } from '../layouts'
import { Button } from '../components'
import * as styles from '../styles/pages/confirm-email'

export default () => (
  <PageLayout sharingTitle="Aito.ai" description="Please confirm your email address to continue with the sign up process">
    <Helmet>
      <title>Aito.ai</title>
    </Helmet>
    <styles.Container>
      <styles.Centerer>
        <styles.MailIcon />
      </styles.Centerer>
      <styles.Centerer>
        <styles.Header>
          Your account was created
        </styles.Header>
      </styles.Centerer>
      <styles.Centerer>
        <styles.Text>
          However, before continuing we need to verify your email address.
          A verification link has been sent to you.
          Please check your email and follow the instructions.
        </styles.Text>
      </styles.Centerer>
      <styles.Centerer>
        <a href="https://console.aito.ai/">
          <Button size="small">Log in to Aito console</Button>
        </a>
      </styles.Centerer>
    </styles.Container>
  </PageLayout>
)
