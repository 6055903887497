import styled from 'styled-components'
import { Mail } from '@styled-icons/octicons'
import variables from '../variables'
import { headerStyles } from '../global'
import { Heading } from '../../components'

export const Container = styled.div`
  width: 100%;
  padding: 100px 100px 150px 100px;
  background: ${variables.colors.silverTree.darker};
`
export const Centerer = styled.div`
  display: flex;
  justify-content: center;
`

export const Header = styled(Heading)`
  ${headerStyles.headerSmall}
  color: white;
  margin-bottom: 20px;
`

export const MailIcon = styled(Mail)`
  height: 80px;
  margin-bottom: 40px;
  color: white;
`

export const Text = styled.div`
  color: white;
  max-width: 500px;
  text-align: center;
  margin-bottom: 24px;
`
